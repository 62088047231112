@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4 cursor-pointer;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 225, 0.8),
        hsla(242, 63%, 58%, 0.8)
    ) !important;
}

.fixed {
    position: fixed;
}

.bottom-10 {
    bottom: 2.5rem;
}

.right-10 {
    right: 2.5rem;
}

.p-3 {
    padding: 0.75rem;
}

.rounded-full {
    border-radius: 9999px;
}

.bg-pink-600 {
    background-color: #d53f8c;
}

.text-white {
    color: #ffffff;
}

.text-3xl {
    font-size: 1.875rem;
}

.hover\:bg-pink-700:hover {
    background-color: #b83280;
}

.transition {
    transition-property: all;
}

.duration-300 {
    transition-duration: 300ms;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 1s ease-out forwards;
}

input,
textarea {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

input:focus,
textarea:focus {
    background-color: #e2e8f0;
    transform: scale(1.02);
}

button {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
    transform: scale(1.05);
}

@media (max-width: 640px) {
    .px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .text-4xl {
        font-size: 2.25rem;
    }

    .text-2xl {
        font-size: 1.5rem;
    }

    .w-full {
        width: 100%;
    }
}

#tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.icon-hover:hover {
    color: #d53f8c;
}

body {
    font-family: 'Raleway', sans-serif;
}

.fixed {
    position: fixed;
}

.bg-[#0a192f] {
    background-color: #0a192f;
}

.text-gray-300 {
    color: #d1d5db;
}

.text-pink-600 {
    color: #d53f8c;
}

.hover\:text-pink-600:hover {
    color: #d53f8c;
}

.transition {
    transition: all 0.3s ease;
}

.duration-300 {
    transition-duration: 300ms;
}
/* src/components/CustomCursor.css */
.custom-cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    background-color: rgb(216, 19, 196); /* Change this to your desired cursor color */
    border-radius: 50%;
    pointer-events: none; /* Ensures the cursor does not interfere with other elements */
    transform: translate(-50%, -50%);
    z-index: 9999;
    transition: transform 0.15s ease;
  }
  body {
    cursor: none; /* This hides the default cursor */
  }
  /* src/components/CustomCursor.css */
/* src/components/CustomCursor.css */
.custom-cursor {
    width: 20px;
    height: 20px;
    background-color: #ec4899; /* Tailwind pink-500 */
    border-radius: 50%;
    pointer-events: none;
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: 9999;
    transition: transform 0.15s ease, width 0.15s ease, height 0.15s ease, background-color 0.15s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .custom-cursor.hovered {
    width: 30px;
    height: 30px;
    background-color: #db2777; /* Tailwind pink-600 */
  }
  .client-icon {
    color: rgb(255, 10, 141);
    transition: color 0.3s ease;
  }
  
  .client-icon:hover {
    color: pink;
  }
  